import { call, takeLatest, put } from 'redux-saga/effects';
import UserRosterReducer from 'Redux/Reducers/RosterReducer/rosterReducer';
import UserLoadingReducer from 'Redux/Reducers/CommonReducer/loadingReducer';
import  UserClassRosterReducer  from 'Redux/Reducers/ClassReducer/classRosterReducer';
import { createNotification } from 'Config/notificationtoast';
import { GET_ROSTER_LIST, ADD_ROSTER, DELETE_ROSTER, SEARCH_ROSTER, IMPORT_ROSTER } from 'Actions/constants';
import {
    AddRoster_API,
    GetRoster_API,
    DeleteRoster_API,
    SearchRoster_API,
    importRoster_API
} from 'Config/Api';
import {
    chroniclecloud_code
} from 'Config/Constant';
import { decryptData, encryptData, logOut } from 'Utils/Helper';
import request from 'Config/request';
import { getRandomColor } from "Utils/Helper";


export function* userRosterModuleSaga() {
    yield takeLatest(GET_ROSTER_LIST, getRosterList);
    yield takeLatest(ADD_ROSTER, addRoster);
    yield takeLatest(DELETE_ROSTER, deleteRoster);
    yield takeLatest(SEARCH_ROSTER, searchRoster);
    yield takeLatest(IMPORT_ROSTER, importRoster);
}

function* addRoster(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = AddRoster_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        // "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        // "student_color_code": getRandomColor(),
        // "academic_year_id": action.data.academic_year_id,
        // "student_pic_size": action.data.student_pic_size,
        // "student_first_name": action.data.student_first_name,
        // "student_last_name": action.data.student_last_name,
        // "student_registration_email_id": action.data.student_registration_email_id,
        // "student_secondary_email_id": action.data.student_secondary_email_id,
        // "student_pic_url": action.data.student_pic_url,
        // "student_id": action.data.student_id,
        // "class_id": action.data.class_id,
        // "by_class_sharing": action.data.by_class_sharing,




        "role": data.user_type,
        "first_name": action.data.student_first_name,
        "last_name": action.data.student_last_name,
        "email": action.data.student_registration_email_id,
        "student_profile_url": action.data.student_pic_url,
        // "school_id": data.school_id,
        "teacher_id": data.cc_user_id,
        "academic_year_id":action.data.academic_year_id,
        "student_color_code": getRandomColor(),
        "class_id": action.data.class_id,
        "student_pic_size": `${action.data.student_pic_size}`,
        "by_class_sharing": action.data.by_class_sharing,
        "platform": "web"
    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            let value = {
                class_id: action.data.class_id,
                filter_by: action.data.filter_by,
                page_no: action.data.page_no,
                academic_year: action.data.academic_year,
                limit: action?.data?.limit
            }

            yield put(UserRosterReducer.addRosterSuccess(MainDecode));
            yield put(UserRosterReducer.getRosterList(value));
            // createNotification('success', MainDecode.message);
        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterReducer.addRosterFailure(MainDecode));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', error);
        yield put(UserRosterReducer.addRosterFailure(false));
    }
}

function* getRosterList(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    let URL = ''
    if(action.data.class_id > 0) {
        URL = `${GetRoster_API}?user_id=${data.cc_user_id}&academic_year=${action.data.academic_year}&class_id=${action.data.class_id}&page_no=${action.data.page_no}&limit=${action.data.limit ? action.data.limit : '12'}&filter_by=${action.data.filter_by}&orderBy=${action.data.name_filter_by}&platform=web`;
    } else {
        URL = `${GetRoster_API}?user_id=${data.cc_user_id}&academic_year=${action.data.academic_year}&page_no=${action.data.page_no}&limit=${action.data.limit ? action.data.limit : '12'}&filter_by=${action.data.filter_by}&orderBy=${action.data.name_filter_by}&platform=web`;
    }
   
    const BODY = {
        "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        "device_udid": sessionStorage.getItem('UDID'),
        "class_id": action.data.class_id,
        "filter_by": action.data.filter_by,
        "page_no": action.data.page_no,
        "academic_year": action.data.academic_year,
        "orderBy" : action.data.name_filter_by,
        "search_data" : action.data.search_data

    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        const MainDecode = decryptData(response.res);
        if (MainDecode.status == 200) {
            yield put(UserRosterReducer.getRosterListSuccess(MainDecode));
        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterReducer.getRosterListFailure(MainDecode));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Somthing went wrong');
        yield put(UserRosterReducer.getRosterListFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }
}

function* deleteRoster(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = DeleteRoster_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        // "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "student_id": action.data.student_id,
        "role" : data.user_type,
        "is_deleted" : "1",
        "platform"  :'web'
    }

    let response;
    try {
        response = yield call(request, URL, {
            method: 'PUT',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            let value = {
                class_id: action.data.class_id,
                filter_by: action.data.filter_by,
                page_no: action.data.page_no,
                academic_year: action.data.academic_year,
                limit: action.data.limit
            }

            yield put(UserRosterReducer.deleteRosterSuccess(MainDecode));
            yield put(UserRosterReducer.getRosterList(value));
            // createNotification('success', MainDecode.message);
        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterReducer.deleteRosterFailure(MainDecode));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRosterReducer.deleteRosterFailure(false));
    }
}

function* searchRoster(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = `${SearchRoster_API}?user_id=${data?.cc_user_id}&search_string=${(action.data.search_string)?.trim()}&academic_year_id=${action.data.academic_year_id}&page=1&limit=500&platform=web`;
    let response;
    try {
        response = yield call(request, URL, {
            method: 'GET',
            // body: { "data": btoa(BODY) },
        });
        console.log('MainDecodeURL :>> ', URL);
        localStorage.setItem('MainDecodeURL', URL);
        const MainDecode = decryptData(response.res)
        console.log('MainDecode :>> ', MainDecode);
        if (MainDecode.status === 200) {
            yield put(UserRosterReducer.searchRosterSuccess(MainDecode));

        } else {
            // createNotification('error', MainDecode.message);
            yield put(UserRosterReducer.searchRosterFailure(MainDecode));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }
        }
    } catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRosterReducer.searchRosterFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }
}


function* importRoster(action) {
    let data = JSON.parse(sessionStorage.getItem('UserData'))
    const URL = importRoster_API;
    const BODY = {
        // "chroniclecloud_code": chroniclecloud_code,
        "user_id": data.cc_user_id,
        // "device_udid": sessionStorage.getItem('UDID'),
        "student_data": action.data.student_data,
        "academic_year_id": action.data.academic_year_id,
        "class_id" : action?.data?.class_id > 0 ? action.data.class_id : '',
        "platform" : "web"
    }
    let response;
    try {
        response = yield call(request, URL, {
            method: 'POST',
            body: { "data": encryptData(BODY) },
        });
        const MainDecode = decryptData(response.res)
        if (MainDecode.status === 200) {
            let value = {
                class_id: action.data.class_id,
                filter_by: action.data.filter_by,
                page_no: action.data.page_no,
                academic_year: action.data.academic_year_id,
                search_data : "",
                limit: action?.data?.limit
            }
            yield put(UserRosterReducer.importRosterSuccess(MainDecode));
            if(action.data.importFromViewSingleClass) {
            const getClassRsoterValue = {
                "class_id": action.data.class_id,
                "filter_by": "by_class",
                "page_no": 1,
                "academic_year": action.data.academic_year_id,
                "limit": action?.data?.limit,
                "search_data": ""
            }
            yield put(UserClassRosterReducer.getClassRosterList(getClassRsoterValue));
            } else {
                yield put(UserRosterReducer.getRosterList(value));
            }
            // yield put(UserRosterReducer.getRosterList(value));
         
            
            // createNotification('success', MainDecode.message);

        } else {
            createNotification('error', MainDecode.message);
            yield put(UserRosterReducer.importRosterFailure(MainDecode));
            if (MainDecode.message === "Your session has been expired. Please login again") {
                logOut('error', MainDecode.message)
            }

        }
} catch (error) {
        createNotification('warning', 'Something went wrong');
        yield put(UserRosterReducer.importRosterFailure(false));
        yield put(UserLoadingReducer.loadingSuccess(false));

    }
}
